/*
 * @Author: 哈库呐玛塔塔
 * @Date: 2022-11-14 16:55:42
 * @Descripttion: 
 * @LastEditors: 哈库呐玛塔塔
 * @LastEditTime: 2022-11-16 17:25:02
 */
import React, { useEffect, useState } from "react"
import MdWrapper from "../../../../../components/MarkdownWrap"
import Axios from "axios"
import { useLocation } from "@reach/router"
import styled from "styled-components"

const Links = styled.div`
  max-width: 1000px;
  width: 100%;
  padding: 45px 45px 8px;
  margin: 0 auto;
  position: sticky;
  top: -37px;
  z-index: 1;
  background: white;
`

const Anchor = styled.a`
  display: block;
  font-size: 12px;
  line-height: 20px;
`

const Privacy = () => {
  const [de, setDE] = useState(null)
  const [en, setEN] = useState(null)
  const location = useLocation()
  useEffect(() => {
    document.documentElement.lang = "de"
    getMd()
  }, [])

  const getMd = () => {
    Axios.get(`${process.env.resourceUrl}docs/nebula_h5_docs/pp/dt/PrivacyPolicy_DE.md`).then(function (data) {
      setDE(data.data)
    })
    Axios.get(`${process.env.resourceUrl}docs/nebula_h5_docs/pp/dt/PrivacyPolicy_EN.md`).then(function (data) {
      setEN(data.data)
    })
  }
  return (
    <>
      <Links>
        <Anchor href="#de">Deutsche Fassung(German Version)</Anchor>
        <Anchor href="#en">English Version(Englische Fassung)</Anchor>
      </Links>
      <div id="de"></div>
      <MdWrapper md={de} blankLayout />
      <div id="en"></div>

      <MdWrapper md={en} blankLayout />
    </>
  )
}

export default Privacy
